import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={4}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 4</H2>

        <p>Were you able to answer your question? How satisfied are you with the answers you found? Are there still things you would like to find out before you are comfortable answering your original question?</p>
        <p>Write 2-3 questions you would ask either to confirm your answer or to resolve any uncertainties you still have.</p>
        <p>How could you get that information?</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

